var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("App Update Setting")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.update))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" maintance Mode ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.maintance),callback:function ($$v) {_vm.$set(_vm.update, "maintance", $$v)},expression:"update.maintance"}},[(_vm.update.maintance)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1),_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Task ON OFF ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.task_on_off),callback:function ($$v) {_vm.$set(_vm.update, "task_on_off", $$v)},expression:"update.task_on_off"}},[(_vm.update.task_on_off)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1),_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Version","vid":"version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Current App Version ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"version","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.version),callback:function ($$v) {_vm.$set(_vm.update, "version", $$v)},expression:"update.version"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Version","vid":"dlink","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App Download link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"dlink","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.dlink),callback:function ($$v) {_vm.$set(_vm.update, "dlink", $$v)},expression:"update.dlink"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("App Setting's")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.setting))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Main Notice Box","vid":"notice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Main Notice Box ")]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"number","id":"notice","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.setting.notice),callback:function ($$v) {_vm.$set(_vm.setting, "notice", $$v)},expression:"setting.notice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Payment Notice Box","vid":"payment_notich","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Payment Notice Box ")]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"number","id":"payment_notich","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.setting.payment_notich),callback:function ($$v) {_vm.$set(_vm.setting, "payment_notich", $$v)},expression:"setting.payment_notich"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }